.module-icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.4rem;
}

.rolle-icon {
    font-size: 1.4rem;
    color: rgb(255, 255, 255, 0.5);
}

.card-dashboard {
    cursor: pointer;
    padding-top: 30px !important;
}

.card-dashboard span {
    display: inline-flex;
    height: 35px;
}

.role-container {
    display: flex;
    align-items: center;
}

.role-desc {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.role-desc-label {
    padding: 0 !important;
    margin: 0;
    height: auto !important;
    font-size: 0.8rem;
    color: rgb(65, 60, 60);
}

.role-desc-label__value {
    padding: 0 !important;
    margin: 0;
    height: auto !important;
    font-size: 0.7rem;
}

.title-span {
    font-size: 1.1rem !important;
    color: inherit !important;
    display: flex;
    justify-content: center;
}

.page-header .page-title {
    white-space: nowrap;
}


.role-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 57px;
    min-height: 50px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: normal !important;
    color: #fff;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    text-transform: capitalize;
    background-size: 200% auto;
    border: 1px solid transparent;
    background-image: linear-gradient(to left, #418DC7 0%, #63C5F7 51%, #418DC7 100%);
    /* background-image: linear-gradient(to left, rgb(14, 22, 117, 0.4) 0%, #63C5F7 51%, rgb(14, 22, 117, 0.4) 100%); */
}

.role-icon:hover {
    color: #fff !important;
    background-position: right center;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
}


.users-data-roles span {
    font-size: 20px;
    font-weight: normal;
    display: flex;
    align-items: end;
    padding: 5px 5px;
}