/* .block-quote {
  font: 13px Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 10px 20px;
  color: #333333;
} */

blockquote {
  background: #f4f4f4;
  border: 1px solid #ffff;
  border-left: 3px solid #929292;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.5em 1em;
  display: block;
  word-wrap: break-word;
}

p {
  font-weight: normal !important;
}
.endpoint{
  font-size: small !important;
}

.endpoint:hover{
  text-decoration: underline !important;
}
