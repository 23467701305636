.unauthorize{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.unauthorize h5{
    text-align: center;
    margin-bottom: 10px;
}

.unauthorize h5 span{
    color: #c4183c;
}